<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Lab 3 - Report Sheet Questions</h2>

      <p class="mb-3">
        a) Answer each of the questions below to indicate whether or not you were able to watch the
        relevant videos and identify proper hazards related to Meker and Bunsen burners.
      </p>

      <v-simple-table>
        <tr>
          <td>
            <stemble-latex content="$\text{Task}$" />
          </td>
          <td>
            <stemble-latex content="$\text{Yes}$" />
          </td>
          <td>
            <stemble-latex content="$\text{No}$" />
          </td>
        </tr>
        <tr v-for="(task, i) in completionTasks1" :key="task.value">
          <td>
            {{ task.text }}
          </td>
          <td>
            <v-radio-group
              v-model="inputs[`inputA${i + 1}`]"
              :disabled="!allowEditing"
              class="my-n4 py-n4"
            >
              <v-radio key="yes" value="yes" />
            </v-radio-group>
          </td>
          <td>
            <v-radio-group
              v-model="inputs[`inputA${i + 1}`]"
              :disabled="!allowEditing"
              class="my-n4 py-n4"
            >
              <v-radio key="no" value="no" />
            </v-radio-group>
          </td>
        </tr>
      </v-simple-table>

      <p class="mb-3 mt-5">
        b) For the table below, please select "Yes" or "No" to indicate whether or not you performed
        the activities described. Just as note, these are the activities you should perform from now
        on at the end of every lab period in the Chemistry Department.
      </p>

      <v-simple-table>
        <tr>
          <td>
            <stemble-latex content="$\text{Task}$" />
          </td>
          <td>
            <stemble-latex content="$\text{Yes}$" />
          </td>
          <td>
            <stemble-latex content="$\text{No}$" />
          </td>
        </tr>
        <tr v-for="(task, i) in completionTasks2" :key="task.value">
          <td class="my-n4 py-n4">
            {{ task.text }}
          </td>
          <td>
            <v-radio-group
              v-model="inputs[`inputB${i + 1}`]"
              :disabled="!allowEditing"
              class="my-n4 py-n4"
            >
              <v-radio key="yes" value="yes" />
            </v-radio-group>
          </td>
          <td>
            <v-radio-group
              v-model="inputs[`inputB${i + 1}`]"
              :disabled="!allowEditing"
              class="my-n4 py-n4"
            >
              <v-radio key="no" value="no" />
            </v-radio-group>
          </td>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'OleMissExperiment3CompletionQuestions',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        inputA1: null,
        inputA2: null,
        inputA3: null,
        inputA4: null,
        inputA5: null,
        inputA6: null,
        inputA7: null,
        inputA8: null,
        inputA9: null,
        inputA10: null,
        inputA11: null,
        inputA12: null,

        inputB1: null,
        inputB2: null,
        inputB3: null,
        inputB4: null,
        inputB5: null,
        inputB6: null,
        inputB7: null,
        inputB8: null,
      },
      completionTasks1: [
        {
          text: 'I was able to watch a video or demo on how to correctly set-up a Meker Burner',
          value: 'setupMeker',
        },
        {
          text: 'I was able to watch a video or demo on how to correctly turn on a Meker Burner.',
          value: 'turnOnMeker',
        },
        {
          text: 'I was able to watch a video or demo on how to correctly turn off a Meker Burner.',
          value: 'turnOffMeker',
        },
        {
          text: 'I was able to watch a video or demo on how to correctly adjust a Meker Burner.',
          value: 'adjustMeker',
        },
        {
          text: 'I was able to watch a video or demo on how to correctly store a Meker Burner.',
          value: 'storeMeker',
        },
        {
          text: 'I was able to correctly explain the hazards and precautions associated with operating a Meker Burner.',
          value: 'hazardsMeker',
        },
        {
          text: 'I was able to watch a video or demo on how to correctly set-up a Bunsen Burner.',
          value: 'setupBunsen',
        },
        {
          text: 'I was able to watch a video or demo on how to correctly turn on a Bunsen Burner.',
          value: 'turnOnBunsen',
        },
        {
          text: 'I was able to watch a video or demo on how to correctly turn off a Bunsen Burner.',
          value: 'turnOffBunsen',
        },
        {
          text: 'I was able to watch a video or demo on how to correctly adjust a Bunsen Burner.',
          value: 'adjustBunsen',
        },
        {
          text: 'I was able to watch a video or demo on how to correctly store a Bunsen Burner.',
          value: 'storeBunsen',
        },
        {
          text: 'I was able to correctly explain the hazards and precautions associated with operating a Bunsen Burner.',
          value: 'hazardsBunsen',
        },
      ],

      completionTasks2: [
        {
          text: 'Cleaned and dried the glassware I used during the day.',
          value: 'cleanedGlassware',
        },
        {
          text: 'Wiped down my lab bench work area.',
          value: 'wipedDownArea',
        },
        {
          text: 'Checked that there were no foreign objects in the sink or sink drain at my workstation.',
          value: 'checkedSink',
        },
        {
          text: 'Checked that the balance I used was clean and cleaned it if needed.',
          value: 'checkedBalance',
        },
        {
          text: 'Made sure all the equipment I used during the day was put back in its original place.',
          value: 'equipmentBack',
        },
        {
          text: 'Made sure my glassware drawer was shut and locked.',
          value: 'drawerLocked',
        },
        {
          text: 'Made sure to wash my hands to prevent accidentally taking chemicals outside the lab.',
          value: 'washedHands',
        },
        {
          text: 'Checked with my TA before I left the lab',
          value: 'checkedWithTA',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
